import './App.css';

function App() {
  return (
    <div className="App">
      <div className="container">
        <header className="App-header">
          <h1>TOMASZ KRUPA</h1>
          <div className="animated-text">
            <p>WELCOME</p>
            <p>WELCOME</p>
            <p>WELCOME</p>
            <p>WELCOME</p>
            <p>WELCOME</p>
            <p>WELCOME</p>
            <p>WELCOME</p>
            <p>WELCOME</p>
            <p>WELCOME</p>
            <p>WELCOME</p>
          </div>
        </header>
        <main className="App-main">
        </main>
      </div>
    </div>
  );
}

export default App;